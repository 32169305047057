import React from 'react';
import Calendar from './Calendar';

function ProjectCard({ project }) {
    return (
        <div className="project-card" onClick={() => window.location.href = project.link}>
            <h2>{project.name}</h2>
            <p>{project.description}</p>
        </div>
    );

};

export default ProjectCard;