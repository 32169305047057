import React, { useState, useEffect } from 'react';
import './App.css';  // For your styles
import Navbar from './components/NavBar';
import CalendarCard from './components/CalendarCard';

// Component for each project card
const ProjectCard = ({ project }) => {
    return (
        <div className="project-card" onClick={() => window.location.href = project.link}>
            <h2>{project.name}</h2>
            <p>{project.description}</p>
        </div>
    );
};


// Main Component for all projects
function App() {
  
  const [isVisible, setIsVisible] = useState(false); // Calendar visibility
  const [showIcon, setShowIcon] = useState(true); // Show the scroll icon
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const projects = [
    {
        name: "somo.ride (songrelated.com)",
        description: "A developer toolkit for creative minds, inspired by travels and adventures.",
        link: "https://songrelated.com"
    },
    {
        name: "apparrivederci.com",
        description: "An HR app development platform helping to build connections easily.",
        link: "https://apparrivederci.com"
    },
    {
        name: "lescreps.com",
        description: "A bio-link creation tool and quote generator for social media creators.",
        link: "https://lescreps.com"
    },
    {
        name: "ksoik.com",
        description: "A ticketing and event management service, focusing on HR and relationships.",
        link: "https://ksoik.com"
    },
    {
        name: "forensik.cc",
        description: "A research platform for pricing options in the biking world.",
        link: "https://forensik.cc"
    },
  ];


  // Function to handle arrow click
  const handleArrowClick = () => {
    setIsCalendarVisible(true);
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  };


  // Handle the click event to reveal the calendar and hide the icon
  const handleIconClick = () => {
    setIsCalendarVisible(true);
    // Scroll to calendar smoothly
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  };

  // Monitor scroll event to toggle the icon visibility
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsCalendarVisible(false);
    }
    if (window.scrollY > 100) {
      setIsCalendarVisible(true); // Show the calendar
    } else {
      setIsCalendarVisible(false); // Hide the calendar when scrolling back up
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  
  return (
    <div className="App">
      <Navbar />
       {/* Scroll Icon */}
       {!isCalendarVisible && (
        
        <div className="scroll-icon" onClick={handleArrowClick}>
          ↓
        </div>
      )}
      
      <main className="projects-container" style={styles.main}>
        {/* Your interactive calendar or other content can go here */}
        {/*<CalendarCard  isVisible={isCalendarVisible}/>*/}
        {projects.map((project, index) => (
              <ProjectCard key={index} project={project} />
          ))}
        {/*<h2>Interactive Calendar Goes Here</h2>*/}
      </main>

      <footer>
          <div className="footer-info">
              <p>forensik.cc: Bikes & Pricing Research</p>
              <p>swarm-networks.com: Connecting websites and tools with tupace.com and oepbak.</p>
          </div>
      </footer>
      
    </div>

  );
}

const styles = {
  main: {
    marginTop: '100px', // Adds space under the fixed navbar
    padding: '20px',
    position: 'relative',
    zIndex: '1',
    textAlign: 'center',
  },

  arrowContainer: {
    position: 'absolute',
    top: '90vh',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '30px',
    cursor: 'pointer',
    zIndex: 999,
  },
  arrow: {
    animation: 'bounce 2s infinite', // Optional: you can add a bouncing animation for the arrow
  },
};


export default App;

